// Github Logo
.animated-github-logo {
  .github-logo-path {
    fill: none;
    stroke: black;
    stroke-width: 1;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-opacity: 1;
  }
  .dot {
    opacity: 0;
    fill: black;
    stroke: none;
  }
}

.image-with-description {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  .photo {
    img {
      border-radius: 50%;
    }
  }

  .description {
    .branding {
      position: relative;
      border-bottom: 1px dashed red;
      margin-bottom: 1rem;
      h2 {
        position: relative;
        font-family: "Titillium Web", sans-serif;
        font-size: 2rem;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-shadow: 4px 4px 1px #444;
        line-height: 80%;

        @media screen and (min-width: 768px) {
          font-size: 3rem;
        }
        @media screen and (min-width: 1024px) {
          font-size: 4rem;
        }
      }
      h3 {
        font-family: "Rock Salt", cursive;
        font-size: 0.75rem;
        position: relative;
        right: 0;
        bottom: 0;
        color: #ffff00;
        // transform: rotate(-4deg);
        text-shadow: 2px 2px 2px #3e0000;
      }
    }

    color: white;
    .summary {
      text-shadow: 2px 2px 1px #444;
    }
  }
}

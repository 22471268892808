@import url(https://fonts.googleapis.com/css2?family=Rock+Salt);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@900&family=Rock+Salt);
.mcb-animated-logo{width:120px}.mcb-animated-logo #red path{fill:none;stroke:#d40000;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1}.mcb-animated-logo #bw path{fill:none;stroke:#fff;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1}

header,.header{background-color:black;padding-left:1.5rem;padding-right:1.5rem}header .container .columns,.header .container .columns{padding-top:0.75rem}header .container .columns .name a,.header .container .columns .name a{color:white}header .container .columns .tagline a,.header .container .columns .tagline a{color:yellow}header .container .columns .tagline .button.is-link.is-inverted.is-outlined,.header .container .columns .tagline .button.is-link.is-inverted.is-outlined{background-color:transparent;border-color:yellow;color:yellow}header .container .columns .tagline .button.is-link.is-inverted.is-outlined:hover,.header .container .columns .tagline .button.is-link.is-inverted.is-outlined:hover{background-color:yellow;color:black}

.animated-github-logo .github-logo-path{fill:none;stroke:black;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1}.animated-github-logo .dot{opacity:0;fill:black;stroke:none}

.animated-linkedin-logo .linkedin-outline,.animated-linkedin-logo .letter{fill:none;stroke:black;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1}

.animated-twitter-logo .twitter-path{fill:none;stroke:black;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1}

.animated-codepen-logo .circle-border{opacity:1;fill:none;fill-opacity:1;fill-rule:evenodd;stroke:#000000;stroke-width:60;stroke-linecap:square;stroke-linejoin:round;stroke-miterlimit:4;stroke-opacity:1}.animated-codepen-logo .top-rect{opacity:1;fill:none;fill-opacity:1;fill-rule:evenodd;stroke:#000000;stroke-width:37.27863693;stroke-linecap:square;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1}.animated-codepen-logo .bottom-rect{opacity:1;fill:none;fill-opacity:1;fill-rule:evenodd;stroke:#000000;stroke-width:37.27863693;stroke-linecap:square;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1}.animated-codepen-logo .pillar{opacity:0;fill:#000000;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:1.81168628;stroke-linecap:square;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1}

.card{background:transparent;cursor:pointer}.card:hover{-webkit-animation:lighten 2s forwards;animation:lighten 2s forwards}.card .card-image{background-color:rgba(255,255,255,0.1);padding-top:1.5rem;padding-bottom:1.5rem;color:black}.card .card-content{background-color:rgba(255,255,255,0.4);color:whitesmoke;font-family:"Rock Salt", cursive;height:0px;padding:0;visibility:hidden;-webkit-animation:reveal 1s forwards;animation:reveal 1s forwards}.card .card-content .content{-webkit-animation:fadeIn 2s forwards;animation:fadeIn 2s forwards}@-webkit-keyframes reveal{from{height:0px;padding:0;visibility:hidden}to{height:auto;padding:1.5rem;visibility:visible}}@keyframes reveal{from{height:0px;padding:0;visibility:hidden}to{height:auto;padding:1.5rem;visibility:visible}}@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}@-webkit-keyframes lighten{from{background:transparent}to{background:rgba(255,255,255,0.3)}}@keyframes lighten{from{background:transparent}to{background:rgba(255,255,255,0.3)}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}

footer,.footer{background:none;font-family:"Rock Salt", cursive;font-size:0.75rem;padding:1.5rem}

.image-with-description{margin-top:1.5rem;margin-bottom:1.5rem}.image-with-description .photo img{border-radius:50%}.image-with-description .description{color:white}.image-with-description .description .branding{position:relative;border-bottom:1px dashed red;margin-bottom:1rem}.image-with-description .description .branding h2{position:relative;font-family:"Titillium Web", sans-serif;font-size:2rem;letter-spacing:2px;text-transform:uppercase;text-shadow:4px 4px 1px #444;line-height:80%}@media screen and (min-width: 768px){.image-with-description .description .branding h2{font-size:3rem}}@media screen and (min-width: 1024px){.image-with-description .description .branding h2{font-size:4rem}}.image-with-description .description .branding h3{font-family:"Rock Salt", cursive;font-size:0.75rem;position:relative;right:0;bottom:0;color:#ffff00;text-shadow:2px 2px 2px #3e0000}.image-with-description .description .summary{text-shadow:2px 2px 1px #444}

#skills-chart .title{color:white;font-size:1.5rem;font-weight:600;line-height:1.125;margin-bottom:0}#skills-chart .chart{margin-left:0}#skills-chart .chart li{margin-bottom:0.5rem;list-style:none}#skills-chart .chart li h4{font-size:1rem;font-weight:600;font-style:italic;line-height:1rem;margin-top:0;margin-bottom:0.5rem;border-bottom:none;padding-bottom:0}#skills-chart .chart li .progress{margin-bottom:0}

#resume{color:black}#resume .content{background-color:rgba(255,255,255,0.1);padding:1.5rem;margin-bottom:1.5rem;box-shadow:0 0.5em 1em -0.125em rgba(10,10,10,0.1),0 0 0 1px rgba(10,10,10,0.02)}#resume .content h3,#resume .content h4,#resume .content h5,#resume .content h6{color:black}#resume .content h3{font-size:2rem;margin-bottom:1rem}#resume .content h4{font-size:1.5rem;font-weight:600;line-height:1.125;margin-top:2rem;margin-bottom:0.25rem;border-bottom:1px solid black;padding-bottom:1rem}#resume .content h4 small{font-style:italic;font-size:1rem}#resume .content h5{font-size:1.25em;margin-top:1.5rem;margin-bottom:0}#resume .content h5 small{font-style:italic;font-size:1rem}#resume .content p{font-size:1rem;margin-bottom:0.5rem}#resume .content strong{color:black}#resume .content blockquote{background:none;border-color:black;margin-bottom:1rem;border-width:8px;display:flex;justify-content:flex-start;align-items:center}#resume .content blockquote p{font-size:1.5rem;font-style:italic;font-weight:bolder;padding-left:1.5rem;margin-bottom:0}#resume .content .introduction p{font-size:1.25rem}#resume .downloads .column{border-right:1px solid black}#resume .downloads .column:last-child{border:none}#resume .downloads .column h4{border-bottom:none}#resume .downloads .column a svg{color:black}

html{height:100%}body{min-height:100%;background:#a5219a;background:linear-gradient(135deg, #a5219a 0%, #2989d8 51%, #7db9e8 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#a5219a', endColorstr='#7db9e8',GradientType=1 );background-repeat:no-repeat;color:white}.svg-inline--fa{margin-right:0.5rem}.wrapper{padding-left:1.5rem;padding-right:1.5rem}


#resume {
  color: black;
  .content {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1.5rem;

    margin-bottom: 1.5rem;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
    h3,
    h4,
    h5,
    h6 {
      color: black;
    }

    h3 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    h4 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.125;
      margin-top: 2rem;
      margin-bottom: 0.25rem;
      border-bottom: 1px solid black;
      padding-bottom: 1rem;
      small {
        font-style: italic;
        font-size: 1rem;
      }
    }
    h5 {
      font-size: 1.25em;
      margin-top: 1.5rem;
      margin-bottom: 0;
      small {
        font-style: italic;
        font-size: 1rem;
      }
    }
    p {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    strong {
      color: black;
    }
    blockquote {
      background: none;
      border-color: black;

      margin-bottom: 1rem;

      border-width: 8px;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        font-size: 1.5rem;
        font-style: italic;
        font-weight: bolder;
        padding-left: 1.5rem;
        margin-bottom: 0;
      }
    }
    .introduction {
      p {
        font-size: 1.25rem;
      }
    }
  }
  .downloads {
    .column {
      border-right: 1px solid black;
      &:last-child {
        border: none;
      }
      h4 {
        border-bottom: none;
      }
      a {
        svg {
          color: black;
        }
      }
    }
  }
}

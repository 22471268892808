@import url("https://fonts.googleapis.com/css2?family=Rock+Salt");

.card {
  background: transparent;
  cursor: pointer;
  &:hover {
    animation: lighten 2s forwards;
  }
  .card-image {
    background-color: rgba(255, 255, 255, 0.1);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: black;
  }
  .card-content {
    background-color: rgba(255, 255, 255, 0.4);
    color: whitesmoke;
    font-family: "Rock Salt", cursive;
    height: 0px;
    padding: 0;
    visibility: hidden;
    animation: reveal 1s forwards;
    .content {
      animation: fadeIn 2s forwards;
    }
  }
}

@keyframes reveal {
  from {
    height: 0px;
    padding: 0;
    visibility: hidden;
  }
  to {
    height: auto;
    padding: 1.5rem;
    visibility: visible;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes lighten {
  from {
    background: transparent;
  }
  to {
    background: rgba(255, 255, 255, 0.3);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

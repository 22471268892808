@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@900&family=Rock+Salt");

html {
  height: 100%;
}
body {
  min-height: 100%;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a5219a+0,2989d8+51,7db9e8+100 */
  background: rgb(165, 33, 154); /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    rgba(165, 33, 154, 1) 0%,
    rgba(41, 137, 216, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(165, 33, 154, 1) 0%,
    rgba(41, 137, 216, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(165, 33, 154, 1) 0%,
    rgba(41, 137, 216, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a5219a', endColorstr='#7db9e8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  background-repeat: no-repeat;
  color: white;
}
/* Icons */
.svg-inline--fa {
  margin-right: 0.5rem;
}

.wrapper {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

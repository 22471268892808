// Twitter Logo
.animated-twitter-logo {
  .twitter-path {
    fill: none;
    stroke: black;
    stroke-width: 1;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-opacity: 1;
  }
}

// Codepen Logo
.animated-codepen-logo {
  .circle-border {
    opacity: 1;
    fill: none;
    fill-opacity: 1;
    fill-rule: evenodd;
    stroke: #000000;
    stroke-width: 60;
    stroke-linecap: square;
    stroke-linejoin: round;
    stroke-miterlimit: 4;
    stroke-opacity: 1;
  }
  .top-rect {
    opacity: 1;
    fill: none;
    fill-opacity: 1;
    fill-rule: evenodd;
    stroke: #000000;
    stroke-width: 37.27863693;
    stroke-linecap: square;
    stroke-linejoin: round;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-dashoffset: 0;
    stroke-opacity: 1;
  }
  .bottom-rect {
    opacity: 1;
    fill: none;
    fill-opacity: 1;
    fill-rule: evenodd;
    stroke: #000000;
    stroke-width: 37.27863693;
    stroke-linecap: square;
    stroke-linejoin: round;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-dashoffset: 0;
    stroke-opacity: 1;
  }
  .pillar {
    opacity: 0;
    fill: #000000;
    fill-opacity: 1;
    fill-rule: evenodd;
    stroke: none;
    stroke-width: 1.81168628;
    stroke-linecap: square;
    stroke-linejoin: round;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-dashoffset: 0;
    stroke-opacity: 1;
  }
}

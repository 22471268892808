header,
.header {
  background-color: black;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  .container {
    .columns {
      padding-top: 0.75rem;
      .name {
        a {
          color: white;
        }
      }
      .tagline {
        a {
          color: yellow;
        }
        .button.is-link.is-inverted.is-outlined {
          background-color: transparent;
          border-color: yellow;
          color: yellow;
          &:hover {
            background-color: yellow;
            color: black;
          }
        }
      }
    }
  }
}

// MCB Logo
.mcb-animated-logo {
  width: 120px;
  #red {
    path {
      fill: none;
      stroke: #d40000;
      stroke-width: 1;
      stroke-linecap: butt;
      stroke-linejoin: miter;
      stroke-miterlimit: 4;
      stroke-opacity: 1;
    }
  }
  #bw {
    path {
      fill: none;
      stroke: #fff;
      stroke-width: 2;
      stroke-linecap: butt;
      stroke-linejoin: miter;
      stroke-miterlimit: 4;
      stroke-opacity: 1;
    }
  }
}

#skills-chart {
  .title {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.125;
    margin-bottom: 0;
  }
  .chart {
    margin-left: 0;
    li {
      margin-bottom: 0.5rem;
      list-style: none;
      h4 {
        font-size: 1rem;
        font-weight: 600;
        font-style: italic;
        line-height: 1rem;
        margin-top: 0;
        margin-bottom: 0.5rem;
        border-bottom: none;
        padding-bottom: 0;
      }
      .progress {
        margin-bottom: 0;
      }
    }
  }
}
